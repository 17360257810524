import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import tools from './routes/tools'
import generators from './routes/generators'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import ComingSoonOnly from './routes/ComingSoonOnly'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: {
        name: 'misc-coming-soon'
      }
    },
    {
      path: '/contact',
      redirect: {
        name: 'misc-contact'
      }
    },
    ...ComingSoonOnly, // Temporary, remove corresponding file aswell
    {
      path: '*',
      redirect: 'error-404',
    },
    // { path: '/', redirect: { name: 'misc-landing' } },
    // ...tools,
    // ...generators,
    // ...apps,
    // ...dashboard,
    // ...pages,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...others,
  ],
})

router.beforeEach((to, _, next) => {
  return next()
})

/*
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (to.name === "misc-landing" && isLoggedIn) {
    const userData = getUserData()
    return next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  else if (to.name === "misc-landing" && !isLoggedIn) {
    return next()
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})
*/

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
